import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";

import { cn } from "/lib/utils";

import { sendGTMEvent } from "@next/third-parties/google";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-full text-sm font-semibold transition-colors duration-300 ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-70 border border-current",
  {
    variants: {
      variant: {
        default: "bg-primary text-white hover:bg-primary-400",
        secondary: "bg-secondary border-secondary text-black hover:bg-secondary-600",
        secondaryLight: "bg-secondary-400 border-secondary-400 hover:bg-secondary-300 border-dark",
        outline: "border-primary text-primary bg-transparent hover:bg-primary hover:text-white",
        outlineLight: "border-white/50 text-white bg-transparent hover:!bg-white hover:text-slate-900",
        ghost: "border-0 hover:bg-slate-100 hover:text-slate-900",
        link: "text-dark underline-offset-4 hover:underline !px-0 font-semibold border-0 rounded-none",
        linkLight: "text-white/80 hover:text-white !p-0 border-0",
      },
      size: {
        default: "px-10 py-3 text-[15px]",
        sm: "px-6 py-2",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

const Button = React.forwardRef(({ className, variant, size, asChild = false, children, ...props }, ref) => {
  const Comp = asChild ? Slot : "button";

  const getButtonText = (children) => {
    if (typeof children === "string") return children;
    if (Array.isArray(children)) return children.map((child) => getButtonText(child)).join(" ");
    if (typeof children === "object" && children?.props?.children) return getButtonText(children.props.children);

    return "";
  };

  return (
    <Comp
      className={cn(buttonVariants({ variant, size, className }))}
      ref={ref}
      onClick={() => sendGTMEvent({ event: "buttonClicked", value: getButtonText(children) })}
      {...props}
    >
      {children}
    </Comp>
  );
});
Button.displayName = "Button";

export { Button, buttonVariants };
